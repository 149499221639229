<template>
  <div class="personal">
    <!-- 订单信息 -->
    <div class="personalA clearfix">
      <div class="personal_title clearfix">
        <span style="padding-left: 15px">订单详情</span>
        <span style="padding-left: 15px;font-size: 14px;color: #333;">{{ list.GenerateTime }}</span>
      </div>
      <div>
        <div class="personal_img clearfix">
          <img :src="list.SerImgUrl"
            alt=""
            class="imgurl" />
        </div>
        <div class="personal_info clearfix">
          <div style="position: relative">
            <span>下单人 :{{ list.OMName }}</span>
          </div>
          <div style="margin: 4px 0px">类别 :{{ list.OTypeDesc }}</div>
          <div style="margin: 4px 0px">总数 :{{ list.SerNum }} <span style="padding-left:10px;">金额 :</span>
            <span>¥{{ list.Amount }}元</span></div>
          <div style="margin: 4px 0px">状态 :{{ list.StatusDesc }}</div>
          <div style="margin: 4px 0px">{{ list.OMAddr }}</div>
          <div style="margin: 4px 0px"
            v-if="list.Status == '4'">服务商 :{{ list.EntName }}</div>
          <div style="margin: 4px 0px"
            v-if="list.Status == '4'">服务人员 :{{ list.EntStaff }}</div>
          <div style="margin: 4px 0px"
            v-if="list.Status == '9'">撤单原因 :{{ list.RevReason }}</div>
          <div style="margin: 4px 0px"
            v-if="list.Status == '9'">撤单时间 :{{ list.RevTime }}</div>
          <!-- <div style="margin: 4px 0px">下单时间 :{{ list.GenerateTime }}</div> -->
        </div>
      </div>
    </div>
    <div class="personalA clearfix"
      v-if="list.Status == '5'">
      <div>
        <div class="personal_info clearfix">
          <div style="position: relative">
            <span></span>
          </div>
          <div style="margin: 4px 0px">
            <van-rate v-model="list.Satisfaction"
              :size="25"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
              readonly>
            </van-rate>
          </div>
          <div style="margin: 4px 0px">评价时间 :{{ list.EvaTime }}
            <div style="margin: 4px 0px">{{ list.EvalContent }}</div>
            <div style="margin: 4px 0px"
              v-if="list.EvalImgs != null && list.EvalImgs != ''">
              <span v-for="
              (imgurl,index)
              in
              list.EvalImgs.split(',')"
                :key="index">
                <img :src="url+imgurl"
                  class="imgurl"
                  style="margin:10px;" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="personalA clearfix"
      style="margin-bottom: 60px;">
      <div class="personal_title">
        <span style="padding-left: 15px">服务项目</span>
      </div>
      <!-- <div class="personal_MX clearfix">
        <span class="clearfix">订单类型</span>
        <span class="clearfix">{{ list.OTypeDesc }}</span>
      </div>
      <div class="personal_MX clearfix">
        <span class="clearfix">下单时间</span>
        <span class="clearfix">{{ list.OrderTime }}</span>
      </div> -->
      <!-- 服务列表 -->
      <div v-for="item in fuwulist"
        :key="item.index">
        <div style="display: flow-root;">
          <div class="personal_img clearfix">
            <img :src="item.SImgUrl"
              alt=""
              class="imgurl" />
          </div>
          <div class="personal_info clearfix">
            <div style="position: relative">
              <span>名称 :{{ item.SName }}</span>
            </div>

            <!-- <div style="margin: 4px 0px">分类 :{{ item.SerKindDesc }}</div> -->
            <div style="margin: 4px 0px">数量 :{{ item.SNum }}</div>
            <div style="margin: 4px 0px">
              金额 :{{ item.SAmount }}元
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-dialog v-model="arriveShow"
      title="提示"
      show-cancel-button
      :beforeClose="beforeClose">
      <van-field v-model="dataForm.RevReason"
        label="撤单原因"
        placeholder="请输入撤单原因" />
    </van-dialog>
    <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
      <van-button round
        v-if="list.Status == '1' || list.Status == '2'  || list.Status == '3'"
        block
        type="info"
        color="#ffc002"
        @click="getRevokeOrder(list.OrderId)">撤单</van-button>
    </div>
    <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
      <van-button round
        v-if="list.Status == '4'"
        block
        type="info"
        color="#ffc002"
        @click="appraise(list.OrderId)">评价</van-button>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
Vue.use(Dialog);
import {
  WeGetMyOrder,
  WeGetOrderServiceList,
  WeRevokeOrder,
} from "@/api/ylOrder.js";
import { setOpenId, getOpenId, removeOrderId } from "@/utils/auth";
export default {
  data () {
    return {
      list: [],
      fuwulist: [],
      url: 'http://lycaljdapi.dexian.ren/',
      arriveShow: false,
      dataForm: {
        RevReason: '',
      },
      oid: '',
    };
  },
  mounted () {
    this.getlist();
  },
  methods: {
    // 确认接单
    order () {
      Dialog.confirm({
        title: "提示",
        message: "是否确认接单!",
      })
        .then(() => {
          WeRecviceOrder({
            accToken: this.acctoken,
            OrderId: this.$route.params.id,
          }).then((res) => {
            if (res.data.code == 0) {
              Toast.success("接单成功!");
              this.getlist();
            } else {
              Toast.fail(res.data.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 获取分页
    getlist () {
      WeGetMyOrder({
        openID: getOpenId(),
        orderId: this.$route.params.OId,
      }).then((res) => {
        this.list = res.data.data;
      });
      WeGetOrderServiceList({
        openID: getOpenId(),
        orderId: this.$route.params.OId,
      }).then((res) => {
        console.log(res);
        this.fuwulist = res.data.data;
      });


    },
    // 撤单
    getRevokeOrder: function (row) {
      this.arriveShow = true;
      this.oid = row
    },
    beforeClose (action, done) {
      if (action === "confirm") {
        setTimeout(done, 1000);
        WeRevokeOrder({ OpenID: getOpenId(), OrderId: this.oid, RevReason: this.dataForm.RevReason }).then((res) => {
          if (res.data.code == 0) {
            Toast.success('撤单成功');
            this.oid = ''
            this.getlist();
          }
        });

      } else if (action === "cancel") {
        done(); //关闭
      }
    },
    // 评价
    appraise: function (row) {
      this.$router.push("/ylOrder/orderList/appraise/" + row);
    },
  },
};
</script>
<style scoped>
.personal {
  /* background: #f7f7f9; */
}
.personalA {
  background: #fff;
  width: 90%;
  margin: 15px 5%;
  border-radius: 10px;
  padding-bottom: 15px;
  box-shadow: 0px 0px 5px #ddd;
}
.personalA .personal_title {
  height: 45px;
  width: 100%;
  line-height: 45px;
  border-bottom: 1px solid #ccc;
}
.personalA .personal_img {
  float: left;
  margin: 9px 15px;
}
.personalA .personal_info {
  width: 61%;
  margin: 10px 15px;
  float: left;
  font-size: 14px;
  color: #333;
}
.personalA .personal_MX {
  float: left;
  width: 100%;
  font-size: 14px;
  color: #333;
}
.personalA .personal_MX span:nth-child(1) {
  float: left;
  display: inline-block;
  margin: 8px 15px;
  width: 25%;
}
.personalA .personal_MX span:nth-child(2) {
  float: right;
  margin: 8px 15px;
  display: inline-block;
  width: 55%;
}
.personalA .personal_Buttton {
  width: 100%;
}
.personalA .personal_Buttton button {
  width: 30%;
  border: none;
  padding: 8px 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
}
.order {
  width: 100%;
}
.order button {
  width: 90%;
  margin: 0 5% 15px;
  border: none;
  padding: 8px 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  height: 45px;
  background: #3497fb;
}
.imgurl {
  width: 65px;
  height: 65px;
  border-radius: 8px;
  /* border: 1px solid #ff7e1e; */
}
</style>